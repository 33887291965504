import { SourceType } from "@/app/core/utils/enumDisplayRender";

export enum ConditionType {
  Operable = "OPERABLE",
  Inoperable = "INOPERABLE",
}

export const ConditionTypeDisplay: SourceType[] = [
  {
    value: ConditionType.Operable,
    name: "Operable",
  },
  {
    value: ConditionType.Inoperable,
    name: "Inoperable",
  },
];
