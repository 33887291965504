export enum OrderStatus {
  WaitingForApproval = "WAITING_FOR_APPROVAL",
  Created = "CREATED",
  NotSigned = "NOT_SIGNED",
  Dispatched = "DISPATCHED",
  PickedUp = "PICKED_UP",
  Delivered = "DELIVERED",
  Issues = "ISSUES",
  Cancelled = "CANCELLED",
  Archived = "ARCHIVED",
}
export const OrderStatusDisplay = [
  { name: "Waiting-For-Approval", value: OrderStatus.WaitingForApproval },
  { name: "Created", value: OrderStatus.Created },
  { name: "Pending", value: OrderStatus.NotSigned },
  { name: "Dispatched", value: OrderStatus.Dispatched },
  { name: "Picked-Up", value: OrderStatus.PickedUp },
  { name: "Delivered", value: OrderStatus.Delivered },
  { name: "Issues", value: OrderStatus.Issues },
  { name: "Cancelled", value: OrderStatus.Cancelled },
  { name: "Archived", value: OrderStatus.Archived },
];
